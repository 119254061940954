<template>
  <v-data-table
    dense
    :headers="headers"
    :items="boletines"
    item-key="id"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:[`item.descargar`]="{ item }">
      <v-btn x-small icon color="secondary" @click="descargarBoletin(item.id)" >
        <v-icon dark> mdi-download </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions } from "vuex";
import axios from 'axios';
export default {
  data: () => ({
    boletines: [],
    headers: [
      {
        text: "Nombre Estudiante",
        align: "start",
        value: "nombreEstudiante",
      },
      {
        text: "Periodo",
        align: "start",
        value: "periodo",
      },
      {
        text: "Grupo",
        align: "start",
        value: "nombreGrupo",
      },
      { text: "Promedio", value: "promedioGeneral" },
      { text: "Puesto", value: "puesto" },
      {
        text: "Descargar",
        value: "descargar",
        align: "center",
        sortable: false,
      },
    ],
  }),
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions([
      "ConsultarBoletinesEstudiante"
    ]),
    initialize() {
      this.ConsultarBoletinesEstudiante().then((x) => (this.boletines = x.data));
    },
    descargarBoletin(id) {
      axios
        .get(`api/Boletines/${id}`, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type:
              "application/pdf",
          });
          let nombreArchivo = response.headers["content-disposition"]
            .split(";")[1]
            .replace('filename="', "");
          nombreArchivo = nombreArchivo
            .slice(0, nombreArchivo.length - 1)
            .replaceAll(" ", "");
          nombreArchivo = nombreArchivo.replace("-", "_");

          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nombreArchivo;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
  },
};
</script>