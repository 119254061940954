<template>
    <EstudianteBoletin />
</template>
<script>
import EstudianteBoletin from "@/components/EstudianteBoletin.vue";
export default({
     components:{
        EstudianteBoletin
    }
})
</script>
